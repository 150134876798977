import React from 'react';
import './Car.css';
import editIcon from '../../assets/edit.png';
import deleteIcon from '../../assets/delete.png';

const Car = ({ car, onClick, onEdit, onDelete }) => {
  const price = (price, currency) => {
    if (currency === 'USD') {
      return `$${price}`
    } else if (currency === 'CNY' || currency === 'RMB') {
      return `¥${price}¥`
    } else {
      return `${price} ${currency}`
    }
  };

  return (
    <button
      key={car.id}
      className='car-button'
      onClick={() => onClick(car)}
    >
      <div className='car-container'>
        <div className='car-brand'>
          {car.brand}
        </div>
        <div className='car-model'>
          {car.model}
        </div>
        <div className='car-configuration'>
          {car.configuration}<br /><i>{car.notes}</i>
        </div>
        <div className='car-price'>
          {price(car.price_fob, car.currency)}
        </div>
        <div className='car-price'>
          {price(car.price_cip, car.currency)}
        </div>
        <button className="close-button white" onClick={() => onEdit(car)}>
          <img src={editIcon} alt="edit" className='button-icon' />
        </button>
        <button className="close-button white" onClick={() => onDelete(car)}>
          <img src={deleteIcon} alt="delete" className='button-icon' />
        </button>
      </div>
    </button>
  )
};

export default Car;