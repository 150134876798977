import React, { useState, useImperativeHandle, forwardRef } from 'react';
import './CarForm.css';

const CarForm = forwardRef(({ button, onAction, onClose }, ref) => {
  const [formData, setFormData] = useState({
    brand: '',
    model: '',
    configuration: '',
    production_year: 2024,
    condition: 'new',
    odometer: 0,
    price_fob: 0,
    price_cip: 0,
    currency: 'USD',
    supplier: '',
    notes: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }

  const repopulate = (car) => {
    setFormData({
      brand: car.brand,
      model: car.model,
      configuration: car.configuration,
      production_year: car.production_year,
      condition: car.condition,
      odometer: car.odometer,
      price_fob: car.price_fob,
      price_cip: car.price_cip,
      currency: car.currency,
      supplier: car.supplier,
      notes: car.notes
    })
  };

  const reset = () => {
    setFormData({
      brand: '',
      model: '',
      configuration: '',
      production_year: 2024,
      condition: 'new',
      odometer: 0,
      price_fob: 0,
      price_cip: 0,
      currency: 'USD',
      supplier: '',
      notes: ''
    })
  };

  useImperativeHandle(ref, () => ({
    repopulate,
    reset,
  }));

  return (
    <div className='car-add-background' onClick={onClose}>
      <div className='car-add-container' onClick={(e) => e.stopPropagation()}>
        <div className="field">
          <label className="label">
            Марка
          </label>
          <input
            type="text"
            name="brand"
            value={formData.brand}
            onChange={handleInputChange}
            className="input"
            placeholder=""
          />
        </div>

        <div className="field">
          <label className="label">
            Модель
          </label>
          <input
            type="text"
            name="model"
            value={formData.model}
            onChange={handleInputChange}
            className="input"
            placeholder=""
          />
        </div>

        <div className="field">
          <label className="label">
            Конфигурация
          </label>
          <input
            type="text"
            name="configuration"
            value={formData.configuration}
            onChange={handleInputChange}
            className="input"
            placeholder=""
          />
        </div>

        <div className="field">
          <label className="label">
            Год выпуска
          </label>
          <input
            type="number"
            name="production_year"
            value={formData.production_year}
            onChange={handleInputChange}
            className="input"
            placeholder=""
          />
        </div>

        <div className="field">
          <label className="label">
            Состояние
          </label>
          <input
            type="text"
            name="condition"
            value={formData.condition}
            onChange={handleInputChange}
            className="input"
            placeholder=""
          />
        </div>

        <div className="field">
          <label className="label">
            Одометр
          </label>
          <input
            type="number"
            name="odometer"
            value={formData.odometer}
            onChange={handleInputChange}
            className="input"
            placeholder=""
          />
        </div>

        <div className="field">
          <label className="label">
            Цена FOB
          </label>
          <input
            type="number"
            name="price_fob"
            value={formData.price_fob}
            onChange={handleInputChange}
            className="input"
            placeholder=""
          />
        </div>

        <div className="field">
          <label className="label">
            Цена CIP
          </label>
          <input
            type="number"
            name="price_cip"
            value={formData.price_cip}
            onChange={handleInputChange}
            className="input"
            placeholder=""
          />
        </div>

        <div className="field">
          <label className="label">
            Валюта
          </label>
          <input
            type="text"
            name="currency"
            value={formData.currency}
            onChange={handleInputChange}
            className="input"
            placeholder=""
          />
        </div>

        <div className="field">
          <label className="label">
            Поставщик
          </label>
          <input
            type="text"
            name="supplier"
            value={formData.supplier}
            onChange={handleInputChange}
            className="input"
            placeholder=""
          />
        </div>

        <div className="field">
          <label className="label">
            Заметки
          </label>
          <input
            type="text"
            name="notes"
            value={formData.notes}
            onChange={handleInputChange}
            className="input"
            placeholder=""
          />
        </div>

        <div />

        <button
          onClick={() => onAction(formData)}
          className={`button active`}
        >
          {button}
        </button>
      </div>
    </div>
  )
});

export default CarForm;