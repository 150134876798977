import React from "react";
import { Link } from "react-router-dom";
import './AdminHeader.css';

const AdminHeader = () => (
  <header className="header admin">
    <div className="admin-header-container">
      <nav className="nav admin">
        <Link to="/">Главная</Link>
        <Link to="/admin/panel">Калькулятор</Link>
        {/* <Link to="/admin/settings">Settings</Link> */}
        {/* <Link to="/admin/users">Users</Link> */}
      </nav>
    </div>
  </header>
);

export default AdminHeader;