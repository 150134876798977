import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './AdminLogin.css';
import { useAuth } from '../../AuthContext';
import Background from '../../components/Background';

const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const isFormValid = () => {
    return username.length !== 0 && password.length !== 0
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    if (await login(username, password)) {
      navigate("/admin/panel");
    } else {
      alert("Invalid credentials!");
    }
  };

  return (
    <div className='login-main'>
      <Background />

      <div className='login-container'>
        <form className='login-form' onSubmit={handleLogin}>
          <div className="login-field">
            <label className="label">
              Логин
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="input"
            />
          </div>

          <div className="login-field">
            <label className="label">
              Пароль
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input"
            />
          </div>

          <button
            type="submit"
            className={`button ${isFormValid() ? 'active' : 'disabled'}`}
            disabled={isFormValid}
          >
            Войти
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;