import React, { useState } from 'react';
import './Home.css';
import Form from '../components/Form';
import Privacy from '../Privacy';
import Background from '../components/Background';
import logo from '../assets/logo.png';

const Home = () => {
  const [isPrivacyVisible, setIsPrivacyVisible] = useState(false)

  const handleLogoTap = (e) => {
    e.preventDefault()
    window.location.reload();
  }

  const openPrivacy = (e) => {
    e.preventDefault()
    setIsPrivacyVisible(true)
  }

  const closePrivacy = (e) => {
    e.preventDefault()
    setIsPrivacyVisible(false)
  }

  return (
    <div className="main">
      <Background />
      
      <div className="container">
        <div className="header">
          <a href="#" onClick={handleLogoTap}>
            <img src={logo} alt="logo" style={{ width: "auto", height: "40pt" }} />
          </a>
          <a className="call" href="tel://+375298889868">
            +375 (29) 888-98-68
          </a>
        </div>

        <div className="content">
          <div className="title">
            Лучшие цены на авто из Китая<br />
            Новые и с пробегом
          </div>
        </div>

        <div className="footer">
          <Form />
          <p className="footer-privacy">
            Нажимая кнопку "Отправить", вы принимаете условия{" "}
            <button className="footer-privacy-link" onClick={openPrivacy}>
              Пользовательского соглашения
            </button>{" "}
          </p>
        </div>
      </div>

      {isPrivacyVisible && <Privacy isVisible={isPrivacyVisible} onClose={closePrivacy} />}
    </div>
  );
}

export default Home;