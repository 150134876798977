import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation, Navigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import AdminHeader from "../components/admin/AdminHeader";
import AdminLogin from "../pages/admin/AdminLogin";
import AdminPanel from "../pages/admin/AdminPanel";
// import Settings from "../pages/admin/Settings";
// import Users from "../pages/admin/Users";

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/admin" />;
};

const AdminLayout = () => (
  <>
    <AdminHeader />
    <main className="main admin">
      <Routes>
        <Route path="/" element={<AdminLogin />} />
        <Route path="/panel" element={<ProtectedRoute element={<AdminPanel />} />} />
        {/* <Route path="settings" element={<ProtectedRoute element={<Settings />} />} /> */}
        {/* <Route path="users" element={<ProtectedRoute element={<Users />} />} /> */}
      </Routes>
    </main>
  </>
);

export default AdminLayout;