import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import axios from 'axios';
import './Cars.css';
import Car from './Car';
import addIcon from '../../assets/add.png';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Cars = forwardRef(({ onClick, onEdit, onDelete, onAdd }, ref) => {
  const [cars, setCars] = useState([]);
  
  const getEffectivePrice = (car) => {
    if (car.price_fob === 0) return car.price_cip;
    if (car.price_cip === 0) return car.price_fob;
    return Math.min(car.price_fob, car.price_cip);
  };

  const loadCars = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/cars`);
      setCars(response.data.sort((a, b) => {
        if (a.brand !== b.brand) {
            return a.brand.localeCompare(b.brand);
        }
        if (a.model !== b.model) {
            return a.model.localeCompare(b.model);
        }
        return getEffectivePrice(a) - getEffectivePrice(b);
      }));
    } catch {
      alert("Ошибка при загрузке!");
    }
  };

  useEffect(() => {
    loadCars();
  })

  const reload = async () => {
    await loadCars();
  };

  useImperativeHandle(ref, () => ({
    reload,
  }));

  return (
    <div className='cars-container'>
      <div className='cars-header'>
        <div className='car-brand'><b>Марка</b></div>
        <div className='car-model'><b>Модель</b></div>
        <div className='car-configuration'><b>Конфигурация</b></div>
        <div className='car-price'><b>FOB</b></div>
        <div className='car-price'><b>CIP</b></div>
        <div style={{ width: '24pt' }} />
        <button className="close-button white" onClick={onAdd}>
          <img src={addIcon} alt="add" className='button-icon' />
        </button>
      </div>

      <div className='cars-list'>
        {cars.map((car, index) => (
          <Car car={car} onClick={onClick} onEdit={onEdit} onDelete={onDelete} />
        ))}
      </div>
    </div>
  )
});

export default Cars;