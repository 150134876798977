import React, { useState, useImperativeHandle, forwardRef } from 'react';
import axios from 'axios';
import './Calc.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Calc = forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    price: '',
    fob: true,
    cip: false,
    delivery: 2500,
    customs: 800,
    profit: 0
  });

  const [car, setCar] = useState(0)
  const [delivery, setDelivery] = useState(0)
  const [customs, setCustoms] = useState(0)
  const [total, setTotal] = useState(0)
  const [customerUSD, setCustomerUSD] = useState(0)
  const [customerBYN, setCustomerBYN] = useState(0)
  const [usdRate, setUSDRate] = useState(0)

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleFOB = () => {
    setFormData(prevData => ({
      ...prevData,
      fob: true,
      cip: false,
      delivery: 2500
    }))
  }

  const handleCIP = () => {
    setFormData(prevData => ({
      ...prevData,
      fob: false,
      cip: true,
      delivery: 0
    }))
  }

  const handleCalc = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/admin/calc`, formData);
      setCar(response.data.car)
      setDelivery(response.data.delivery)
      setCustoms(response.data.customs)
      setTotal(response.data.total)
      setCustomerUSD(response.data.customer_usd)
      setCustomerBYN(response.data.customer_byn)
      setUSDRate(response.data.usd_rate)
    } catch {
      alert("Ошибка!");
    }
  }

  const recalculate = (car) => {
    if (car.price_fob) {
      setFormData(prevData => ({
        ...prevData,
        price: car.price_fob,
        fob: true,
        cip: false,
        delivery: 2500
      }))
    } else {
      setFormData(prevData => ({
        ...prevData,
        price: car.price_cip,
        fob: false,
        cip: true,
        delivery: 0
      }))
    }
    handleCalc();
  };

  useImperativeHandle(ref, () => ({
    recalculate,
  }));

  return (
    <div className='calc-container'>
      <div className='calc-type'>
        <button
          onClick={handleFOB}
          className={`${formData.fob ? "button active" : "button active inactive"}`}
        >
          FOB
        </button>

        <button
          onClick={handleCIP}
          className={`${formData.cip ? "button active" : "button active inactive"}`}
        >
          CIP
        </button>
      </div>

      <div className="field">
        <label className="label">
          {`Цена ${formData.fob ? "FOB" : "CIP"} USD`}
        </label>
        <input
          type="text"
          name="price"
          value={formData.price}
          onChange={handleInputChange}
          className="input"
          placeholder="0"
        />
      </div>

      {formData.fob && <div className="field">
        <label className="label">
          Доставка USD
        </label>
        <input
          type="text"
          name="delivery"
          value={formData.delivery}
          onChange={handleInputChange}
          className="input"
          placeholder="0"
        />
      </div>}

      <div className="field">
        <label className="label">
          Таможня USD
        </label>
        <input
          type="text"
          name="customs"
          value={formData.customs}
          onChange={handleInputChange}
          className="input"
          placeholder="0"
        />
      </div>

      <div className="field">
        <label className="label">
          Прибыль USD
        </label>
        <input
          type="text"
          name="profit"
          value={formData.profit}
          onChange={handleInputChange}
          className="input"
          placeholder="0"
        />
      </div>

      <div />

      <button
        onClick={handleCalc}
        className={`button active`}
      >
        Рассчитать
      </button>

      <p>
        <>Курс USD / BYN НБРБ на сегодня: <b>{usdRate}</b> <br /><br /></>

        <><b>Для компании</b><br /></>
        <>Машина USD: <b>${car}</b> <br /></>
        {formData.fob && <>Доставка USD: <b>${delivery}</b> <br /></>}
        <>Таможня USD: <b>${customs}</b> <br /></>
        <>Стоимость в Минске USD: <b>${total}</b> <br /></>
        <>Прибыль: <b style={{ color: formData.profit > 0 ? 'green' : 'red' }}>${formData.profit}</b> <br /><br /></>

        <><b>Для клиента</b><br /></>
        <>Стоимость Минске USD: <b>${customerUSD}</b> <br /></>
        <>Стоимость Минске BYN НБРБ +1%: <b>{customerBYN} BYN</b> <br /></>
      </p>
    </div>
  )
});

export default Calc;