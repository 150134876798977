import './Privacy.css';
import React, { useState } from 'react';

const Privacy = ({ isVisible, onClose }) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = (e) => {
    setIsClosing(true)
    setTimeout(() => {
      setIsClosing(false)
      onClose(e)
    }, 300)
  }

  return (
    <div className={`popup-overlay ${isVisible ? "visible" : ""}`} onClick={handleClose}>
      <div
        className={`popup-container ${isClosing ? "slide-out" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="popup-header">
          <h2>Пользовательское соглашение</h2>
          <button className="close-button" onClick={handleClose}>
            &times;
          </button>
        </div>
        <div className="popup-content">
          <p>
            Настоящее Пользовательское соглашение (далее – «Соглашение») регулирует отношения между ООО “Юнайтед Авто Трейд”, УНП 193816345, расположенным по адресу: г. Минск, ул. Прушинских, 31а, каб. 79, владельцем сайта, расположенного по адресу: <a className="link" href="https://car2.by">car2.by</a> (далее – «Сайт»), именуемым в дальнейшем «Администрация», с одной стороны, и любым пользователем сети Интернет, посетившим Сайт, именуемым в дальнейшем «Пользователь», принявшим публичное предложение (оферту) о заключении настоящего Соглашения, с другой стороны.
          </p>
          <h3>
            1. Принятие Соглашения и согласие на обработку данных
          </h3>
          <p>
            1.1. Пользователь, отправляя заявку на поиск и подбор интересующего автомобиля, считается принявшим оферту о заключении настоящего Соглашения. Пользователь дает согласие Администрации на сбор, обработку, хранение, распространение и предоставление третьим лицам своих персональных данных.
          </p>
          <p>
            1.2. Под персональными данными понимаются: фамилия, имя, отчество, пол, дата рождения, семейное положение, адрес регистрации, номер телефона, адрес электронной почты, а также иные данные, позволяющие идентифицировать Пользователя.
          </p>
          <p>
            1.3. Администрация может собирать техническую информацию, автоматически передаваемую устройством Пользователя, включая данные из файлов cookie, информацию о браузере, IP-адрес, дату и время доступа, запрашиваемые страницы, а также иные технические характеристики устройства.
          </p>
          <h3>
            2. Цели обработки данных
          </h3>
          <p>
            Персональные данные Пользователя собираются и обрабатываются в следующих целях:
          </p>
          <ul>
            <li>предоставление доступа к функционалу Сайта;</li>
            <li>обеспечение работы всех сервисов Сайта;</li>
            <li>уведомление о продуктах, услугах и акциях Администрации и ее партнеров;</li>
            <li>заключение, изменение и расторжение Соглашения;</li>
            <li>обеспечение коммуникации с Пользователем;</li>
            <li>размещение Пользователем сообщений и материалов на Сайте;</li>
            <li>анализ данных для улучшения качества услуг;</li>
            <li>соблюдение требований законодательства;</li>
            <li>для других целей с согласия Пользователя.</li>
          </ul>
          <h3>
            3. Порядок обработки персональных данных
          </h3>
          <p>
            3.1. Администрация может передавать персональные данные третьим лицам, в том числе на территории иностранных государств, при условии обеспечения надлежащего уровня их защиты.
          </p>
          <p>
            3.2. Пользователь дает согласие на обработку персональных данных на срок, необходимый для достижения целей, указанных в п. 2 настоящего Соглашения.
          </p>
          <h3>
            4. Права Пользователя
          </h3>
          <p>
            Пользователь имеет право:
          </p>
          <ul>
            <li>запрашивать у Администрации информацию о своих персональных данных и их обработке;</li>
            <li>вносить изменения в свои персональные данные;</li>
            <li>отозвать согласие на обработку персональных данных;</li>
            <li>требовать удаления персональных данных, если они более не требуются для целей, указанных в п. 2 настоящего Соглашения;</li>
            <li>получать информацию о передаче своих данных третьим лицам;</li>
            <li>пользоваться другими правами, предусмотренными законодательством.</li>
          </ul>
          <h3>
            5. Ограничения при использовании Сайта
          </h3>
          <p>
            5.1. Пользователю запрещено размещать материалы, содержащие:
          </p>
          <ul>
            <li>государственные тайны или коммерческую информацию, защищенную законом;</li>
            <li>данные, пропагандирующие наркотики, экстремизм, насилие или иную запрещенную информацию;</li>
            <li>сведения, нарушающие законодательство страны проживания Пользователя или Республики Беларусь.</li>
          </ul>
          <h3>
            6. Отказ от обработки данных и расторжение Соглашения
          </h3>
          <p>
            Для отзыва согласия на обработку данных или расторжения Соглашения, Пользователь может обратиться в Администрацию по адресу электронной почты: <a className="link" href="mailto://info@car2.by">info@car2.by</a>.
          </p>
          <h3>
            7. Изменение условий Соглашения
          </h3>
          <p>
            Администрация оставляет за собой право вносить изменения в настоящее Соглашение. Продолжение использования Сайта Пользователем после внесения изменений подтверждает согласие с обновленными условиями.
          </p>
          <h3>
            8. Контактная информация
          </h3>
          <p>
            По всем вопросам, связанным с настоящим Соглашением, Пользователь может обращаться по адресу: <a className="link" href="mailto://info@car2.by">info@car2.by</a> или по телефону: <a className="link" href="tel://+375298889868">+375 (29) 888-98-68</a>.
          </p>
          <br/>
          <p>
            Соглашение вступает в силу с момента его принятия Пользователем.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Privacy;