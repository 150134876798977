import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import './AdminPanel.css';
import { useAuth } from '../../AuthContext';
import Background from '../../components/Background';
import Calc from '../../components/admin/Calc';
import Cars from '../../components/admin/Cars';
import CarForm from '../../components/admin/CarForm';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AdminPanel = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const calcRef = useRef(null);
  const carsRef = useRef(null);
  const carFormRef = useRef(null);

  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [carId, setCarId] = useState(null);

  useEffect(() => {
    if ((showAdd || showUpdate) && typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showAdd, showUpdate])

  const addCar = async (formData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/cars`, formData);
      console.log(response)
      console.log(response.status)
      console.log(response.data)
      if (response.status === 200) {
        if (carFormRef.current) {
          carFormRef.current.reset();
        }
        setShowAdd(false);
        if (carsRef.current) {
          await carsRef.current.reload();
        }
      }
    } catch (error) {
      alert("Форма заполнена неверно!");
    }
  };

  const updateCar = async (formData) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/api/cars/${carId}`, formData);
      console.log(response)
      console.log(response.status)
      console.log(response.data)
      if (response.status === 200) {
        if (carFormRef.current) {
          carFormRef.current.reset();
        }
        setShowUpdate(false);
        if (carsRef.current) {
          await carsRef.current.reload();
        }
      }
    } catch (error) {
      alert("Произошла ошибка!");
    }
  };

  const deleteCar = async (car) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/api/cars/${car._id}`);
      console.log(response)
      console.log(response.status)
      console.log(response.data)
      if (response.status === 200) {
        if (carsRef.current) {
          await carsRef.current.reload();
        }
      }
    } catch (error) {
      alert("Произошла ошибка!");
    }
  };

  const handleCarAdd = () => {
    setShowAdd(true);
  }

  const handleCarClick = (car) => {
    if (calcRef.current) {
      calcRef.current.recalculate(car);
    }
  }

  const handleCarUpdate = async (car) => {
    setCarId(car._id);
    await setShowUpdate(true); // wait until carFormRef is set
    if (carFormRef.current) {
      carFormRef.current.repopulate(car);
    }
  }

  const handleCarDelete = (car) => {
    const userConfirmed = window.confirm(`Удалить запись ${car.brand} ${car.model} ${car.configuration}?`);
    if (userConfirmed) {
      deleteCar(car);
    }
  }

  const handleLogout = () => {
    logout()
    navigate("/admin")
  }

  return (
    <div className='panel-main'>
      <Background blurred />

      <div className='panel-container'>
        <div className='panel-calc'>
          <Calc ref={calcRef} />
        </div>

        <Cars
          ref={carsRef}
          onClick={handleCarClick}
          onEdit={handleCarUpdate}
          onDelete={handleCarDelete}
          onAdd={handleCarAdd}
        />
      </div>

      {showAdd && <CarForm ref={carFormRef} button={'Добавить'} onAction={addCar} onClose={() => setShowAdd(false)} />}
      {showUpdate && <CarForm ref={carFormRef} button={'Обновить'} onAction={updateCar} onClose={() => setShowUpdate(false)} />}
    </div>
  );
};

export default AdminPanel;