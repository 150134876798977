import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../components/Header";
import Home from "../pages/Home";
import AdminLogin from "../pages/admin/AdminLogin";
// import About from "../pages/About";
// import Catalog from "../pages/Catalog";

const Layout = () => (
  <>
    {/* <Header /> */}
    <main>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/catalog" element={<Catalog />} /> */}
      </Routes>
    </main>
  </>
);

export default Layout;