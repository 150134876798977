import React, { useCallback, useEffect, useState } from 'react';
import './Form.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Form = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    details: ''
  });

  const isValidName = useCallback(() => {
    return formData.name.trim() !== ''
  }, [formData.name])

  const isValidPhone = useCallback(() => {
    const phoneRegex = /^\+?[0-9\-()+\s]*$/;
    if (phoneRegex.test(formData.phone)) {
      const clean = cleanPhone(formData.phone)
      if (clean.startsWith('+')) {
        if (clean.startsWith('+375')) {
          return clean.length === 13
        } else {
          return clean.length === 12 || clean.length === 13
        }
      } else if (clean.startsWith('8')) {
        return clean.length === 11
      } else {
        return clean.length >= 10 || clean.length === 12
      }
    } else {
      return false
    }
  }, [formData.phone])

  const cleanPhone = (phone) => {
    return phone.replace(/[^0-9+]/g, '')
  }

  const [errors, setErrors] = useState({
    name: !isValidName(),
    phone: !isValidPhone()
  });

  const isFormValid = () => !errors.name && !errors.phone;

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    if (isFormValid()) {
      const data = {
        name: formData.name,
        phone: cleanPhone(formData.phone),
        details: formData.details
      };

      try {
        const response = await fetch(`${API_BASE_URL}/api/send-form`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data)
        });

        if (response.status === 200) {
          alert('Ваша заявка успешно отправлена!');
          setFormData({ name: '', phone: '', details: '' });
        } else {
          alert('Произошла ошибка!');  
        }
      } catch (error) {
        alert('Произошла ошибка!');
      }
    } else {
      alert('Форма заполнена неверно');
    }
  }

  useEffect(() => {
    setErrors({
      name: !isValidName(),
      phone: !isValidPhone()
    })
  }, [formData, isValidName, isValidPhone])

  return (
    <div className="form">
      <div className="fields">
        <div className="field">
          <label className="label">
            Имя
          </label>
          <input
            type="text"
            autoComplete='name'
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="input"
            placeholder="Иван Иванов"
          />
        </div>

        <div className="field">
          <label className="label">
            Телефон
          </label>
          <input
            type="tel"
            autoComplete='tel'
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            className="input"
            placeholder="+375XXYYYYYYY"
          />
        </div>

        <div className="field">
          <label className="label">
            Ваш запрос
          </label>
          <input
            name="details"
            value={formData.details}
            onChange={handleInputChange}
            className="input"
            placeholder="BYD Song Plus 2025"
          />
        </div>
      </div>
      
      <button
        type="submit"
        className={`button ${isFormValid() ? 'active' : 'disabled'}`}
        disabled={!isFormValid()}
        onClick={handleSubmitForm}
      >
        Отправить
      </button>
    </div>
  );
};

export default Form;
