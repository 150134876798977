import React, { useState, createContext, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation, Navigate } from "react-router-dom";
import Home from "./pages/Home"
import AdminLogin from "./pages/admin/AdminLogin"
import AdminPanel from "./pages/admin/AdminPanel"
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkSession = async () => {
    try {
      await axios.get(`${API_BASE_URL}/api/admin/data`);
      setIsAuthenticated(true);
    } catch {
      setIsAuthenticated(false);
    }
  };

  const login = async (username, password) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/admin/login`, { username, password });
      if (response.data.success) {
        setIsAuthenticated(true);
        return true;
      }
    } catch {
      return false;
    }
  };

  const logout = async () => {
    await axios.post(`${API_BASE_URL}/api/admin/logout`);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    checkSession();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;