import './Background.css';
import background from '../assets/background.avif'

const Background = ({ blurred }) => {
  return (
    <div className="background-container">
      <img src={background} alt="background" className="background" />
      <div className="gradient-top"></div>
      <div className="gradient-bottom"></div>
      { blurred && <div className='blur' /> }
    </div>
  )
}

export default Background;